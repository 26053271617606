// Define privilege
function Privilege(name, displayName) {
  this.name = name;
  this.displayName = displayName;
}

const Privileges = {
  ReadRoles: 'READ_ROLES',
  WriteRoles: 'WRITE_ROLES',
  ReadUser: 'READ_USER',
  WriteUser: 'WRITE_USER',
  ReadCompany: 'READ_COMPANY',
  WriteCompany: 'WRITE_COMPANY',

  LergImport: 'IMPORT_LERG',
  ViewLerg: 'VIEW_LERG',
  RateImport: 'IMPORT_RATE',
  ViewRate: 'VIEW_RATE',
  CDRImport: 'IMPORT_CDR',
  ViewCDR: 'VIEW_CDR',
  LCRReport: 'LCR_REPORT',
  CPRReport: 'CPR_REPORT',
  NewCPRReport: 'NEW_CPR_REPORT',
};

export const DisplayNames = {
  [Privileges.ReadRoles]: 'Read Role',
  [Privileges.WriteRoles]: 'Write Role',
  [Privileges.ReadUser]: 'Read User',
  [Privileges.WriteUser]: 'Write User',
  [Privileges.ReadCompany]: 'Read Company',
  [Privileges.WriteCompany]: 'Write Company',

  [Privileges.LergImport]: 'Lerg Import',
  [Privileges.ViewLerg]: 'View Lerg',
  [Privileges.RateImport]: 'Rate Import',
  [Privileges.ViewRate]: 'View Rate',
  [Privileges.CDRImport]: 'CDR Import',
  [Privileges.ViewCDR]: 'View CDR',
  [Privileges.LCRReport]: 'LCR Report',
  [Privileges.CPRReport]: 'CPRgen Report',
  [Privileges.NewCPRReport]: 'New CPRgen Report',
};

export const AdminPrivileges = [
    Privileges.ReadRoles, Privileges.WriteRoles,
    Privileges.ReadUser, Privileges.WriteUser,
    Privileges.ReadCompany, Privileges.WriteCompany,
    Privileges.LergImport, Privileges.ViewLerg,
    Privileges.ViewCDR, Privileges.ViewRate, Privileges.CDRImport, 
    Privileges.RateImport, Privileges.LCRReport, Privileges.CPRReport, Privileges.NewCPRReport
];

export default Privileges;

export const TIMEOUT_VALUE                  = 1000 * 60 * 60;  // session timeout value
export const LAST_ACTIVE_TIME_COOKIE_NAME   = 'LATV';          // old time value cookie name
