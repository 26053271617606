import React from 'react'
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Redirect, Route} from "react-router-dom";
import withSuspense from '../components/HOC/withSuspense';

// imporing related to session timeout
import IdleTimer from 'react-idle-timer';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { TIMEOUT_VALUE, LAST_ACTIVE_TIME_COOKIE_NAME } from '../constants/Privileges';
import {logout as logOutActionCreator} from "../redux/AuthRedux";


class AuthRoute extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
    component: PropTypes.elementType.isRequired,
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    key: PropTypes.string,
    dataKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  };

  static defaultProps = {
    exact: false
  };

  constructor(props) {
    super(props);

    this.state = {
      timeout: TIMEOUT_VALUE,
      isTimedOut: false,
    };

    const { cookies } = this.props;
    let oldTimeVal = cookies.get(LAST_ACTIVE_TIME_COOKIE_NAME) || 0;
    let curTimeVal = Date.now();
    if (curTimeVal < oldTimeVal || curTimeVal - oldTimeVal > TIMEOUT_VALUE) {
      this.props.logout();
    }

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  _onAction(e) {
    console.log('user is something', e);
    this.setState({isTimedOut: false});

    this.setActiveTimeToCookie()
  }

  _onActive(e) {
    console.log('user is active', e);
    this.setState({isTimedOut: false});

    this.setActiveTimeToCookie()
  }

  setActiveTimeToCookie() {
    const { cookies } = this.props;
    cookies.set(LAST_ACTIVE_TIME_COOKIE_NAME, Date.now(), { path: '/' });
  }

  _onIdle(e) {
    console.log('user is idle ', e);
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
    } else {
      this.idleTimer.reset();
      this.props.logout();
    }
  }

  render() {
    const WrappedComponent = this.props.component;
    const checkPaths = ['/login', '/register'];
    const renderRedirect = this.props.authenticated ? checkPaths.includes(this.props.path) : !checkPaths.includes(this.props.path);
    let redirectUrl = this.props.authenticated ? '/dashboard' : '/login';
    if(this.props.profile && !this.props.profile.isChangePassword) redirectUrl = "/reset-password";
    return (<>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout} />
        <Route exact={this.props.exact}
               path={this.props.path}
               key = {this.props.dataKey}
               name = {this.props.name}
               render={ () => (renderRedirect ? <Redirect to={redirectUrl}/> : <WrappedComponent />)
               }
        />
      </>
    )
  }
}

export default withSuspense(connect(
  (state) => ({ authenticated: state.auth.isAuthenticated || false, profile: state.auth.profile}),   // StateToProps
  dispatch => ({
    logout: () => dispatch(logOutActionCreator()),
  })
)(withCookies(AuthRoute)))
